<script lang="ts" setup>
  const generalStore = useGeneralStore()

  const setCountry = function (idx: number) {
    generalStore.activeContactIdx = idx
  }
</script>

<template>
  <div class="country-btn-list">
    <template v-for="(country, idx) in generalStore.contact">
      <button class="country-btn" :class="{ active: idx === generalStore.activeContactIdx }" @click="setCountry(idx)">
        {{ country?.attributes?.name }}
      </button>
    </template>
  </div>
</template>

<style lang="scss">
  .country-btn {
    position: relative;
    min-width: 218px;
    color: #fff;
    font-size: 22px;
    font-weight: 700;
    line-height: 1;
    padding: 0.75rem 2rem;
    border-radius: 6px;
    background-color: #fd9396;
    &.active {
      background-color: #263a75;
      &::after {
        position: absolute;
        content: '';
        top: calc(100% - 1px);
        left: 50%;
        height: 0;
        width: 0;
        border-style: solid;
        border-width: 0.75rem 0.75rem 0;
        border-color: #263a75 transparent transparent;
        transform: translateX(-50%);
      }
    }
    &-list {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      margin-bottom: 1rem;
    }
  }
</style>
